import React, { useEffect, useState, useContext } from "react";
import { Link, navigate } from "gatsby";
import Skeleton from "react-loading-skeleton";
import sanitizeHtml from "sanitize-html";
import PageWrapper from "../../components/PageWrapper";
import { AuthContext } from "../../context/AuthContext";
import { fetchBlogDetails } from "../../api";

const Blog = ({ params }) => {
  const [loading, setLoading] = useState(true);
  const [blog, setBlog] = useState(null);
  const { auth } = useContext(AuthContext);
  const param = params[`*`];

  const getBlogDetails = async (blogId) => {
    const details = await fetchBlogDetails(blogId);
    setBlog(details);
    setLoading(false);
  };

  useEffect(() => {
    const blogId = param;
    getBlogDetails(blogId);
    return () => {
      setLoading(true);
    };
  }, []);

  const createMarkup = (html) => {
    return {
      __html: sanitizeHtml(html, {
        allowedAttributes: false,
        allowedTags: [
          "address",
          "article",
          "aside",
          "footer",
          "header",
          "h1",
          "h2",
          "h3",
          "h4",
          "h5",
          "h6",
          "hgroup",
          "main",
          "nav",
          "section",
          "blockquote",
          "dd",
          "div",
          "dl",
          "dt",
          "figcaption",
          "figure",
          "hr",
          "li",
          "main",
          "ol",
          "p",
          "pre",
          "ul",
          "a",
          "abbr",
          "b",
          "bdi",
          "bdo",
          "br",
          "cite",
          "code",
          "data",
          "dfn",
          "em",
          "i",
          "kbd",
          "mark",
          "q",
          "rb",
          "rp",
          "rt",
          "rtc",
          "ruby",
          "s",
          "samp",
          "small",
          "span",
          "strong",
          "sub",
          "sup",
          "time",
          "u",
          "var",
          "wbr",
          "caption",
          "col",
          "colgroup",
          "table",
          "tbody",
          "td",
          "tfoot",
          "th",
          "thead",
          "tr",
        ],
      }),
    };
  };

  return (
    <>
      <PageWrapper headerConfig={auth ? { button: "profile" } : null}>
        <div className="jobDetails-section bg-default-1 pt-28 pt-lg-27 pb-xl-25 pb-12">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-xl-10 col-lg-10 mt-4 dark-mode-texts">
                <div className="mb-9">
                  <Link
                    to="#"
                    className="d-flex align-items-center ml-4"
                    onClick={(e) => {
                      e.preventDefault();
                      navigate(-1);
                    }}
                  >
                    {" "}
                    <i className="icon icon-small-left bg-white circle-40 mr-5 font-size-7 text-black font-weight-bold shadow-8"></i>
                    <span className="text-uppercase font-size-3 font-weight-bold text-gray">
                      Back
                    </span>
                  </Link>
                </div>
              </div>

              <div className="col-xl-10 col-lg-10 mb-8 px-xxl-15 px-xl-0">
                <div className="bg-white rounded-4 border border-mercury shadow-9">
                  <div className="border-bottom border-width-1 border-default-color light-mode-texts">
                    <div className="row">
                      <div className="col-12">
                        <div
                          className="d-flex bg-black justify-content-center align-items-center border"
                          style={{
                            backgroundImage: `url(${blog?.cover})`,
                            backgroundRepeat: "no-repeat",
                            backgroundSize: "cover",
                            backgroundPosition: "center",
                            opacity: 0.8,
                            minHeight: 300,
                          }}
                        >
                          <h3 className="text-white bg-black text-center w-50 py-4 min-width-px-284">
                            {blog?.title}
                          </h3>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="job-details-content pt-8 pl-sm-9 pl-6 pr-sm-9 pr-6 pb-10 light-mode-texts">
                    <div className="row">
                      <div className="col-xl-11 col-md-12 pr-xxl-9 pr-xl-10 pr-lg-20">
                        <div className=" d-flex justify-content-between">
                          <p className="font-size-3 mb-7">
                            {loading ? (
                              <Skeleton height={24} width={150} />
                            ) : (
                              new Date(blog?.created.toDate()).toDateString()
                            )}
                          </p>
                          {loading ? (
                            <Skeleton height={24} width={100} />
                          ) : auth?.admin ? (
                            <div>
                              <Link
                                to={`/blog-edit/${blog?.blogId}`}
                                state={{ id: blog?.blogId }}
                                className="font-size-3 font-weight-bold text-red-2 text-uppercase"
                              >
                                Edit Blog
                              </Link>
                            </div>
                          ) : null}
                        </div>
                        <div className="">
                          {loading ? (
                            <div>
                              {[1, 2, 3, 4, 5].map((index) => (
                                <p key={index}>
                                  <Skeleton
                                    height={24}
                                    containerClassName="w-100"
                                  />
                                </p>
                              ))}
                            </div>
                          ) : (
                            <div
                              className="font-size-3 mb-7 blog-content"
                              dangerouslySetInnerHTML={createMarkup(
                                blog?.content
                              )}
                            />
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </PageWrapper>
    </>
  );
};

export default Blog;
